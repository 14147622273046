.Main {
    padding-top: 10vmin;
}

.Head1{
    font-size: 8vmin;
    font-weight: 500;
    color: rgb(3, 0,55);
    padding-left: 4vmin;
}

.Head2{
    font-size: 8vmin;
    font-weight: 500;
    color: rgb(3, 0,55);
    padding-left: 4vmin;
    padding-top: 6.5vmin;
}


.MainCBE {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Head1CBE {
  font-size: 6vmin;
  margin-bottom: 1.5em;
  color: #006699; /* set Head1 color */
}
.CardContainerCBE {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 3em;
}
.CardCBE {
  width: 45%;
  min-width: 150px;
  font-size: 4.5vmin;
}
@media screen and (max-width: 768px) {
  .Head1CBE {
    font-size: 9vmin;
  }
  .CardCBE {
    width: 60%;
    font-size: 7.5vmin;
  }
}
@media screen and (max-width: 480px) {
  .Head1CBE {
    font-size: 12vmin;
  }
  .CardCBE {
    width: 90%;
    font-size: 10.5vmin;
  }
}